import { Formik } from "formik";
import { Popup } from "../Popup";
import React from "react"
import { mediaBuyValidationSchema } from "../../helpers/validations";
import style from "../Dashboards/style";

import _ from "lodash";
import axios from "axios";
import { generateMediaBuyName, mediaValidation } from "./helpers";
import { popupFormsConfig as formsConfig }  from "./provider";
import { Snackbar } from "@material-ui/core"
import Alert from '@material-ui/lab/Alert';


class MBPopups extends React.Component {
    state = {
        showVendorForm: false,
        showClientForm: false,
        showOfferForm: false,
        showPublisherForm: false,
        showTypeForm: false,
        showInterestCategoryForm: false,
        showContentForm: false,
        showKeywordForm: false,
        showAffiliateForm: false,
        showGuruForm: false,
        showExtraClientFields: false,
        errorOpened: false,
        error: ''
    }

    componentWillReceiveProps(props) {
        this.setState({
            ...this.state,
            mainFormDetails: props.data
        })
    }

    show(type, data) {
        let state = {...this.state};
        state[type] = true;
        state.formDetails = data;
        this.setState(state)
    }

    async handleFormSave(type) {
        const { formDetails } = this.state
        const validationRes = mediaValidation(type, formDetails);

        if(typeof(validationRes) === 'string') {
            this.setState({
                ...this.state,
                error: validationRes,
                errorOpened: true
            })
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    error: '',
                    errorOpened: false
                })
            }, 4000)
            return
        };
        const config = formsConfig[type];
        
        let options = this.props.deps[config.deps];
        let id = null;

        if (_.has(formDetails, config.fromParam)) {
            id = formDetails[config.fromParam];
            const key = options.findIndex(itm => itm[config.fromParam] === formDetails[config.fromParam])
            const updateResponse = await axios.put(process.env.SERVER_ENDPOINT + config.url, formDetails);
            options = [
                ...options.slice(0, key),
                updateResponse.data,
                ...options.slice(key + 1),
            ]
        } else {
            // create
            const createResponse = await axios.post(process.env.SERVER_ENDPOINT + config.url, formDetails);
            id = createResponse.data[config.fromParam];
            options = [...options, createResponse.data]
        }

        this.setState({
            [config.formToggle]: false,
        })

        this.props.onUpdate({[config.toParam]: id}, {[config.deps]: options})
    }


    render() {
        return (
            <>
                {this.state !== null ?
            <Formik
                enableReinitialize
                initialValues={this.state.data}
                onSubmit={(values, { setSubmitting }) => {
                    this.handleMediaFormSave(values, { setSubmitting });
                }}
                >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        handleSubmit,
                        handleReset
                    } = props;
                    return <div style={style.mainContainer}>
                
                    {Object.keys(formsConfig).map(i => 
                        <Popup
                            key={`popup_form_${i}`}
                            open={this.state[formsConfig[i].formToggle]}
                            title={formsConfig[i].label}
                            mainFormDetails={this.state.mainFormDetails}
                            {...{[`${i}FormDetails`]: this.state.formDetails} }
                            data={this.state.formDetails}
                            onSave={() => this.handleFormSave(i)}
                            onCancel={() => this.setState({ [formsConfig[i].formToggle]: false })}
                            Component={formsConfig[i].component}
                            onContentUpdate={itm =>
                                this.setState(prevState => ({
                                    formDetails: { ...prevState.formDetails, ...itm },
                                }))
                            }
                        />
                    )}
                </div>
                }
            }


            </Formik>
            : null}
            <Snackbar open={this.state.errorOpened} autoHideDuration={6000}>
                <Alert severity="error">
                    {this.state.error}
                </Alert>
            </Snackbar>

            </>
        )
    }
}


export default MBPopups