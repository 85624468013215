import _ from "lodash"
import React, { Component } from "react"
import Select from "react-select";
import axios from "axios";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from "@material-ui/core/TextField"
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { isLinkInternal, getClientName } from '../../../../scripts/global'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, CircularProgress, TextareaAutosize, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { AutocompleteSingle } from "../fields";
import LandingPageForm from "../../../components/LandingPageWidgets/LandingPageForm";

const botScoreOptions = [
  {value: 0.30, label: 'LOW'},
  {value: 0.60, label: 'MEDIUM'},
  {value: 0.90, label: 'HIGH'},
  {value: 0.00, label: 'DISABLED'}
]

const lead_id_options = [
  {
    value: "contact_id=<Contact ID Goes Here>",
    label: "Contact ID"
  },
  {
    value:
        "phone=<Phone Goes Here>&email=<Email Goes Here>&fName=<First Name Goes Here>&lName=<Last Name Goes Here>",
    label: "Name, Email & Phone"
  }
];

const fieldset = { padding: 20, margin: '20px 0', width: '100%' }

export class APILinkOptions extends Component {
  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    const { mainFormDetails, updateFormDetails } = this.props;
    const { internal_link_id } = mainFormDetails;

    if (internal_link_id) {
      const linkOptions = await this.getLinkDetails(internal_link_id)
      const { options } = linkOptions;

      updateFormDetails('api_link_details', JSON.parse(options))
    }
    if(!_.get(mainFormDetails, "post_back_url")) {
      this.generatePostBackURL()
    }
  }

  async generatePostBackURL() {
    const { mainFormDetails, updateFormDetails } = this.props;
    const { internal_link_id } = mainFormDetails;
    if(process.env.ORGANIZATION === "Traders Agency") {
      if (internal_link_id) {
        const linkOptions = await this.getLinkDetails(internal_link_id)
        const { options } = linkOptions;

        updateFormDetails('api_link_details', JSON.parse(options))
      }
    } else { 
      if(mainFormDetails.internal_offer_id) {
        const found = this.props.deps.offers.find(offer => offer.offer_id === mainFormDetails.internal_offer_id)
        if(found) {
          const { data } = await axios.post(`${process.env.SERVER_ENDPOINT}/postBackUrl`, {...mainFormDetails, api_url_template: found.api_url_template})
          updateFormDetails('post_back_url',  data.post_back_url);        }
        
      } else {
        updateFormDetails('post_back_url', '');
      }
    }
  }

  handleLinkOptionChange(key, value) {
    const { mainFormDetails, updateFormDetails } = this.props;
    const { api_link_details } = mainFormDetails;

    updateFormDetails('api_link_details', {
      ...api_link_details,
      link_is_internal: isLinkInternal(mainFormDetails, this.props.deps),
      [key]: value,
    })

  }

  handleChange(value) {
    const { mainFormDetails, updateFormDetails } = this.props;
    updateFormDetails('post_back_url', value);
  }

  async getLinkDetails(id) {
    const { data } = await axios.get(process.env.SERVER_ENDPOINT + `/links/${id}`)

    return _.head(_.get(data, 'links', []));
  }

  render() {

    const { mainFormDetails } = this.props;
    return (
        <>
          {(
              process.env.ORGANIZATION !== "Traders Agency"
          ) && (
              <fieldset style={{...fieldset}}>
                <div>
                  <FormControl component="fieldset" style={{marginTop: '10px'}}>
                    <FormLabel component="legend">Client API Url</FormLabel>
                    <TextareaAutosize
                      variant={'outlined'}
                      margin="dense"
                      style={{ width: "100%", minHeight: '100px' }}
                      value={_.get(mainFormDetails, "post_back_url")}
                      onChange={e => this.handleChange(e.target.value)}                    />
                  </FormControl>
                </div>
              </fieldset>
          )}

          {(
              process.env.ORGANIZATION === "Traders Agency" &&
              getClientName(mainFormDetails) !== "Traders Agency" &&
              getClientName(mainFormDetails) !== "NOFT Traders" && 
              mainFormDetails.send_leads_to_email_system == 1
          ) && (
              <fieldset style={fieldset}>
                <legend style={{ padding: "0 10px" }}>Link Options</legend>
                <div style={{ marginTop: -40 }}>
                  <IterableLinkOptions
                      link_details={mainFormDetails["api_link_details"]}
                      updateFormDetails={this.handleLinkOptionChange.bind(this)}
                  />
                </div>
              </fieldset>
          )}
        </>
    );

  }
};

export class CTOLinkOptions extends Component {
  constructor(props) {
    super(props)

    this.handleLinkOptionChange = this.handleLinkOptionChange.bind(this)
  }

  async generatePostBackURL() {
    if(process.env.ORGANIZATION === "Traders Agency") return; 
    const { mainFormDetails, updateFormDetails } = this.props;
    const { internal_link_id } = mainFormDetails;

    if(mainFormDetails.internal_offer_id) {
      const found = this.props.deps.offers.find(offer => offer.offer_id === mainFormDetails.internal_offer_id)
      if(found) {
        const { data } = await axios.post(`${process.env.SERVER_ENDPOINT}/postBackUrl`, {...mainFormDetails, api_url_template: found.api_url_template})
        updateFormDetails('post_back_url',  data.post_back_url);
      }
      
    } else {
      updateFormDetails('post_back_url', '');
    }
  }

  async componentDidMount() {
    const { mainFormDetails, updateFormDetails } = this.props;
    const { internal_link_id } = mainFormDetails;

    if (internal_link_id) {
      // get link settings
      const linkOptions = await this.getLinkDetails(internal_link_id)
      const { options } = linkOptions;

      updateFormDetails('cto_link_details', {...JSON.parse(options), 
        clicks_ordered: linkOptions.clicks_ordered, 
        expired_destination_url: linkOptions.expired_destination_url,
        bot_score: linkOptions.bot_score
      })
    }
    if(!_.get(mainFormDetails, "post_back_url")) {
      this.generatePostBackURL()
    }
    
  }

  async getLinkDetails(id) {
    const { data } = await axios.get(process.env.SERVER_ENDPOINT + `/links/${id}`)
    return _.head(_.get(data, 'links', []));
  }

  handleLinkOptionChange(key, value) {

    const { mainFormDetails, updateFormDetails } = this.props;
    const { cto_link_details } = mainFormDetails;

    updateFormDetails('cto_link_details', {
      ...cto_link_details,
      link_is_internal: isLinkInternal(mainFormDetails, this.props.deps),
      [key]: value,
    })

  }

  handleChange(value) {
    const { mainFormDetails, updateFormDetails } = this.props;
    updateFormDetails('post_back_url', value);
  }


  render() {
    const { mainFormDetails } = this.props;
    let bot_score = botScoreOptions.find(score => score.value === _.get(mainFormDetails, "cto_link_details.bot_score"))
    if(!bot_score) bot_score = null
    const destinationFilled = _.get(mainFormDetails, "cto_link_details.destination_url") && _.get(mainFormDetails, "cto_link_details.destination_url").length > 0;
    const clicksFilled = _.get(mainFormDetails, "cto_link_details.clicks_ordered") && parseInt(_.get(mainFormDetails, "cto_link_details.clicks_ordered") ) > -1;
    const expURLFilled = _.get(mainFormDetails, "cto_link_details.expired_destination_url") && _.get(mainFormDetails, "cto_link_details.expired_destination_url").length > 0;
    const scoreFilled = typeof(_.get(mainFormDetails, "cto_link_details.bot_score") !== 'undefined');
    return (
        <fieldset style={fieldset}>
          <legend style={{ padding: "0 10px" }}>Link Options</legend>
          <div style={{ marginBottom: 10 }}>
            <TextField
                variant="outlined"
                margin="dense"
                placeholder="Enter Destination URL"
                style={{ width: "100%" }}
                value={_.get(mainFormDetails, "cto_link_details.destination_url")}
                onChange={e => this.handleLinkOptionChange("destination_url", e.target.value)}
            />

            {destinationFilled ?
            <NumberFormat
                onValueChange={e => this.handleLinkOptionChange("clicks_ordered", e.value)}
                placeholder="Enter Number of Unique Clicks Ordered"
                thousandSeparator
                isNumericString
                decimalScale={0}
                prefix=""
                value={_.get(mainFormDetails, "cto_link_details.clicks_ordered")}
                style={{
                  width: '100%',
                  padding: '8px 14px',
                  margin: '8px 0 8px 0',
                  backgroundColor: '#fff',
                  border: '1px solid #E8E8E8',
                  borderRadius: '5px'
                }}
            />
            : null}

            {destinationFilled && clicksFilled ?
            <TextField
                variant="outlined"          
                margin="dense"
                placeholder="“Offer Expired” URL"
                style={{ width: "100%" }}
                value={_.get(mainFormDetails, "cto_link_details.expired_destination_url")}
                onChange={e => this.handleLinkOptionChange("expired_destination_url", e.target.value)}
            />
            : null}
            <br /><br />

            {destinationFilled && clicksFilled && expURLFilled ?
            <Select
                placeholder={"Bot Detection Sensitivity Level"}
                value={_.filter(botScoreOptions, itm => itm['value'] === _.get(mainFormDetails, 'cto_link_details.bot_score'))}
                onChange={selectedOption =>
                    this.handleLinkOptionChange("bot_score", selectedOption['value'])
                }
                options={botScoreOptions}
                
            />
            : null}
            <br />
            
          </div>
          {destinationFilled && clicksFilled && expURLFilled && scoreFilled ?
          <div style={{ marginBottom: 10, marginTop: 40 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Client Lead Submission Method</FormLabel>
              <RadioGroup
                  value={_.get(mainFormDetails, "cto_link_details.post_method", 'parameters')}
                  onChange={e => this.handleLinkOptionChange("post_method", e.target.value)}
              >
                <FormControlLabel
                    value="parameters"
                    control={<Radio />}
                    label="Parameters Appended to Destination URL"
                />
                <FormControlLabel
                    value="webhook"
                    control={<Radio />}
                    label="Post to Client API URL"
                />
              </RadioGroup>
              {process.env.ORGANIZATION !== "Traders Agency" && _.get(mainFormDetails, "cto_link_details.post_method") === 'webhook' ?
                <FormControl component="fieldset" style={{marginTop: '10px'}}>
                  <FormLabel component="legend">Client API URL</FormLabel>
                  <TextareaAutosize
                    variant={'outlined'}
                    margin="dense"
                    style={{ width: "100%", minHeight: '100px' }}
                    value={_.get(mainFormDetails, "post_back_url")}
                    onChange={e => this.handleChange(e.target.value)}
                  />
                </FormControl>
              : null}
            </FormControl>
          </div>
          : null}
        </fieldset>
    );

  }
};

export class WebinarLinkOptions extends Component {

  constructor(props) {
    super(props)
    this.linkDetailsBase = 'webinar_link_details';
    this.state = {
      loading: true,
      accounts: [],
    }

    this.handleLinkOptionChange = this.handleLinkOptionChange.bind(this)

  }

  async componentDidMount() {
    const { mainFormDetails, updateFormDetails } = this.props;
    const { internal_link_id } = mainFormDetails;

    const { accounts } = await this.getG2WAccounts();

    this.setState({ accounts }, async () => {

      if (internal_link_id) {
        // get link settings
        const linkOptions = await this.getLinkDetails(internal_link_id)
        const { options } = linkOptions;
        updateFormDetails(this.linkDetailsBase, JSON.parse(options))
      }

      this.setState({ loading: false })
    })


  }

  async getLinkDetails(id) {
    const { data } = await axios.get(process.env.SERVER_ENDPOINT + `/links/${id}`)
    return _.head(_.get(data, 'links', []));
  }

  async getG2WAccounts() {
    try {
      const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/g2wAccounts");
      const accounts = _.map(_.get(data, 'g2w_api', []), account => ({ value: account['gtw_acct_id'], label: account['gtw_account_name'] }));
      return { accounts };
    } catch (error) {
      console.log("Error occurred")
    }
  }

  handleLinkOptionChange(key, value) {
    const { mainFormDetails, updateFormDetails } = this.props;
    const webinar_link_details = mainFormDetails[this.linkDetailsBase];

    updateFormDetails(this.linkDetailsBase, {
      ...webinar_link_details,
      link_is_internal: isLinkInternal(mainFormDetails, this.props.deps),
      [key]: value,
    })

  }

  render() {
    const { mainFormDetails } = this.props;
    const { loading, accounts } = this.state;

    const goToWebinarFilled = _.get(mainFormDetails, 'webinar_link_details.gtw_acct_id') > 0;
    const kywdFilled = (_.get(mainFormDetails, 'webinar_link_details.kywd') && _.get(mainFormDetails, 'webinar_link_details.kywd').length > 0) ? true : false;
    const destinationFilled = (_.get(mainFormDetails, 'webinar_link_details.destination_url') && _.get(mainFormDetails, 'webinar_link_details.destination_url').length > 0) ? true : false;
    const expiredFilled = (_.get(mainFormDetails, 'webinar_link_details.expired_destination_url') && _.get(mainFormDetails, 'webinar_link_details.expired_destination_url').length > 0) ? true : false;

    return (
        <fieldset style={fieldset}>
          <legend style={{ padding: "0 10px" }}>Link Options</legend>
          <div>
            <div style={{marginBottom: 10}}>
              <Select
                  placeholder={loading ? "Loading..." : "GoToWebinar Account"}
                  value={_.filter(accounts, account => account['value'] === _.get(mainFormDetails, 'webinar_link_details.gtw_acct_id'))}
                  onChange={selectedOption =>
                      this.handleLinkOptionChange("gtw_acct_id", selectedOption['value'])
                  }
                  options={accounts}
              />
            </div>
            {goToWebinarFilled ?
            <div style={{marginBottom: 0}}>
              <TextField
                  variant="outlined"
                  margin="dense"
                  placeholder="Enter a webinar keyword"
                  style={{width: "100%"}}
                  value={_.get(mainFormDetails, `${this.linkDetailsBase}.kywd`)}
                  onChange={e => this.handleLinkOptionChange("kywd", e.target.value)}
              />
            </div>
            : null}
          </div>
          {goToWebinarFilled && kywdFilled ?
          <div style={{ marginBottom: 10 }}>
            <TextField
                variant="outlined"
                margin="dense"
                placeholder="Thank You Page URL"
                style={{ width: "100%" }}
                value={_.get(mainFormDetails, `${this.linkDetailsBase}.destination_url`)}
                onChange={e =>
                    this.handleLinkOptionChange("destination_url", e.target.value)
                }
            />
          </div>
          : null}

          {goToWebinarFilled && kywdFilled && destinationFilled ?
          <div style={{ marginBottom: 10 }}>
            <TextField
                variant="outlined"
                margin="dense"
                placeholder="“No Available Webinars” URL"
                style={{ width: "100%" }}
                value={_.get(mainFormDetails, `${this.linkDetailsBase}.expired_destination_url`)}
                onChange={e =>
                    this.handleLinkOptionChange("expired_destination_url", e.target.value)
                }
            />
          </div>
          : null}

          {goToWebinarFilled && kywdFilled && destinationFilled && expiredFilled ?
          <div style={{ marginBottom: 10 }}>
            <Select
                placeholder="Lead Identification Method"
                value={_.filter(lead_id_options, itm => itm['value'] === _.get(mainFormDetails, `${this.linkDetailsBase}.id_method`))}
                onChange={selectedOption =>
                    this.handleLinkOptionChange("id_method", selectedOption['value'])
                }
                options={lead_id_options}
            />
          </div>
          : null}
          
          {process.env.ORGANIZATION === "Traders Agency" &&
            getClientName(mainFormDetails) !== "Traders Agency" &&
            getClientName(mainFormDetails) !== "NOFT Traders" && 
            mainFormDetails.send_leads_to_email_system == 1 && (
                <IterableLinkOptions
                    link_details={mainFormDetails[this.linkDetailsBase]}
                    updateFormDetails={this.handleLinkOptionChange}
                />
            )}
        </fieldset>
    );
  }
}

export class ODWLinkOptions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      accounts: [],
    }

    this.handleLinkOptionChange = this.handleLinkOptionChange.bind(this)

  }

  async componentDidMount() {
    const { mainFormDetails, updateFormDetails } = this.props;
    const { internal_link_id } = mainFormDetails;
    const { accounts } = await this.getAccounts();

    this.setState({ accounts }, async () => {

      if (internal_link_id) {
        const linkOptions = await this.getLinkDetails(internal_link_id)
        const { options } = linkOptions;
        let parsed = JSON.parse(options);
        updateFormDetails('odw_webinar_link_details', parsed)
        const selectedAccount = _.get(parsed, 'ew_account_id');
        if(selectedAccount) this.getWebinars(selectedAccount);

      }

      this.setState({ loading: false })
    })
  }

  async getLinkDetails(id) {
    const { data } = await axios.get(process.env.SERVER_ENDPOINT + `/links/${id}`)
    return _.head(_.get(data, 'links', []));
  }

  async getAccounts() {
    try {
      const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/everWebinars");
      const accounts = _.map(_.get(data, 'everwebinar_api', []), account => ({ api_key: account['api_key'], value: account['ew_account_id'], label: account['ew_account_name']}));
      return { accounts };
    } catch (error) {
      console.log("Error occurred")
    }
  }

  async getWebinars(value) {
    const { data } = await axios.get(`${process.env.SERVER_ENDPOINT}/webinarjam/${this.state.accounts.find(acc => acc.value === value).api_key}`);
    const { webinars } = data
    this.setState({
      ...this.state,
      webinars: webinars.map(w => ({value: w.webinar_id, schedules: w.schedules, label: w.name}) )
    })
  }

  async handleLinkOptionChange(key, value) {
    const { mainFormDetails, updateFormDetails } = this.props;
    const webinar_link_details = mainFormDetails['odw_webinar_link_details'];
    switch(key) {
      case 'ew_account_id':
        this.getWebinars(value);
        updateFormDetails('odw_webinar_link_details', {
          ...webinar_link_details,
          link_is_internal: isLinkInternal(mainFormDetails, this.props.deps),
          [key]: value,
        })
      break;

      case 'webinar_id':
        let webinar = this.state.webinars.find(w => w.value == value);
        if(webinar) {
          updateFormDetails('odw_webinar_link_details', {
            ...webinar_link_details,
            schedules: webinar.schedules,
            link_is_internal: isLinkInternal(mainFormDetails, this.props.deps),
            [key]: value, schedules: webinar.schedules });
        }
      break;

      default:
        updateFormDetails('odw_webinar_link_details', {
          ...webinar_link_details,
          link_is_internal: isLinkInternal(mainFormDetails, this.props.deps),
          [key]: value,
        })
    }
  }

  render() {
    const { mainFormDetails } = this.props;
    const { loading, accounts, webinars } = this.state;
    
    return (
        <fieldset style={fieldset}>
          <legend style={{ padding: "0 10px" }}>Link Options</legend>
          <div>
            <div style={{marginBottom: 10}}>
              <Select
                  placeholder={loading ? "Loading..." : "EverWebinar Account"}
                  value={_.filter(accounts, account => account['value'] === _.get(mainFormDetails, 'odw_webinar_link_details.ew_account_id'))}
                  onChange={selectedOption =>
                      this.handleLinkOptionChange("ew_account_id", selectedOption['value'])
                  }
                  options={accounts}
              />
            </div>

            {_.get(mainFormDetails, 'odw_webinar_link_details.ew_account_id') > 0 ?
            <div style={{marginBottom: 10}}>
              <Select
                  placeholder={loading ? "Loading..." : "Webinar"}
                  value={_.filter(webinars, webinar => webinar['value'] === _.get(mainFormDetails, 'odw_webinar_link_details.webinar_id'))}
                  onChange={selectedOption => {
                      this.handleLinkOptionChange("webinar_id", selectedOption['value'])
                  }}
                  options={webinars}
              />
            </div>
            : null}
          </div>
          {_.get(mainFormDetails, 'odw_webinar_link_details.webinar_id') > 0 ?
          <div style={{ marginBottom: 10 }}>
            <TextField
                variant="outlined"
                margin="dense"
                placeholder="“No Available Webinars” URL"
                style={{ width: "100%" }}
                value={_.get(mainFormDetails, `odw_webinar_link_details.expired_destination_url`)}
                onChange={e =>
                    this.handleLinkOptionChange("expired_destination_url", e.target.value)
                }
            />
          </div>
          :null}

          {_.get(mainFormDetails, 'odw_webinar_link_details.expired_destination_url') && _.get(mainFormDetails, 'odw_webinar_link_details.expired_destination_url').length > 0 ?
          <div style={{ marginBottom: 10 }}>
            <Select
                placeholder="Lead Identification Method"
                value={_.filter(lead_id_options, itm => itm['value'] === _.get(mainFormDetails, `odw_webinar_link_details.id_method`))}
                onChange={selectedOption =>
                    this.handleLinkOptionChange("id_method", selectedOption['value'])
                }
                options={lead_id_options}
            />
          </div>
          :null}
        </fieldset>
    );
  }
}

export class LandingPageLinkOptions extends Component {
  state = {
    loading: true,
    options: [],
    landingPageOpened: false
  }

  async componentDidMount() {
    this.loadOptions();
  }

  async loadOptions() {
    const { mainFormDetails, updateFormDetails } = this.props;
    const { internal_offer_id } = mainFormDetails;

    this.setState({...this.state, loading: true})
    let response = await axios.get(`${process.env.SERVER_ENDPOINT}/landing-pages?offer=${mainFormDetails.internal_offer_id}`);
    this.setState({
      loading: false,
      options: response.data.data
    })
  }

  handleChange(value) {
    const { updateFormDetails } = this.props;
    updateFormDetails('landing_page_id', value)
    
  }

  async onLandingPageSave(landing_page_id) {
    this.loadOptions();
    this.handleChange(landing_page_id)
    this.onLandingPageCancel();
  }

  onLandingPageCancel() {
    this.setState({
      ...this.state,
      landingPageOpened: false
    })
  }

  render() {
    const { mainFormDetails } = this.props;

    return (
        <fieldset style={fieldset}>
          <legend style={{ padding: "0 10px" }}>Link Page Link</legend>
          <div style={{ marginBottom: 10 }}>
            {!this.state.loading ?
            <>
          <AutocompleteSingle
            readonly={false}
            onChange={value => this.handleChange(value.value)}
            value={ _.get(mainFormDetails, `landing_page_id`)}
            options={this.state.options}
            labelField="name_internal"
            valueField="landing_page_id"
            createLabel={"Create Landing Page"}
            onCreate={() => {this.setState({...this.state, landingPageOpened: true})}}
          />
              {this.state.landingPageOpened ? 
              <Dialog fullScreen={true} open={true}>
              <DialogTitle>Create New Landing Page</DialogTitle>
                <DialogContent style={{maxWidth: '100%', padding: '10px 5px'}}>
                  <LandingPageForm 
                    onSave={id => this.onLandingPageSave(id)}
                    onCancel={() => this.onLandingPageCancel() }
                    defaults={{
                      offer_id: mainFormDetails.internal_offer_id,
                      client_id: mainFormDetails.client_id
                    }}
                  />
                </DialogContent>
              </Dialog>
              : null}
            </>
            : <CircularProgress style={{marginLeft: '50%', position: 'relative', left: '-20px'}} />}
          </div>
        </fieldset>
    );
  }
}

export class IterableLinkOptions extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      projects: [],
    }

  }

  async componentDidMount() {
    const { projects } = await this.getIterableProjects();

    this.setState(prevState => ({
      loading: false,
      projects,
    }))
  }

  async getIterableProjects() {

    try {
      const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/iterableProjects")
      const projects = [
        ..._.map(_.get(data, 'iterable_api', []), (project, k) => ({ value: project['client_id'], label: project['client_name']})),
        { value: false, label: 'No Project' }
      ];

      return { projects };

    } catch (error) {
      console.log("Error occurred!")
    }

  }

  render() {
    const { link_details, updateFormDetails } = this.props;
    const { loading, projects } = this.state;

    const value = _.filter(projects, project => project['value'] === _.get(link_details, 'iterable_project'));

    return (
        <div style={{ marginTop: 30 }}>
          <Select
              placeholder={ loading ? "Loading..." : "Select Iterable Project"}
              value={value}
              onChange={selectedOption =>
                  updateFormDetails("iterable_project", selectedOption['value'])
              }
              options={projects}
          />
        </div>
    );
  }
};