import React from "react"
import Button from "@material-ui/core/Button"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
export class Popup extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { Component, open, title, onSave, onCancel } = this.props
    return (
      <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Component {...this.props} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
