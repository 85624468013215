import _ from "lodash"

export const generateMediaBuyName = ({ eid, internal_source_id, internal_offer_id, internal_medium_id, vendors, offers, types }) => {
    const names = [];
    if (internal_source_id && internal_source_id !== null) {
        const result = _.find(vendors, { "source_id": internal_source_id });
        if(result) {
            names.push(result.source_name);
        }
    }
    if (internal_offer_id && internal_offer_id !== null) {
        const result = _.find(offers, { "offer_id": internal_offer_id });
        if(result) {
            names.push(`${result.offer_name} (${internal_offer_id})`);
        }
    }
    if (internal_medium_id && internal_medium_id !== null) {
        const result = _.find(types, { "medium_id": internal_medium_id });
        if(result) {
            names.push(result.medium_name);
        }
    }
    if (eid) {
        names.push(eid);
    }

    return names.join(' | ')
}

export const resolveStatusName = (status, statuses) => {
    let label = '';
    let found = statuses.find(item => item.value === status);
    if(found) label = found.label;
    return label;
}

export const customValidation = mainFormDetails => {
    const { url_type, eidRequired } = mainFormDetails;
    const link_details = _.get(mainFormDetails, `${url_type}_link_details`, false)

    if(eidRequired){
        if (!mainFormDetails.eid || mainFormDetails.length === 0) {
            alert("Please enter EID")
            return false;
        }   
    }

    if (link_details) {

        if (url_type === 'cto' || url_type === 'webinar' || url_type === 'landing_page') {
            // destination url (format)
            const { destination_url } = link_details
            const isValid = /^(ftp|http|https):\/\/[^ "]+$/.test(destination_url);

            if (!_.has(link_details, 'destination_url') || !_.get(link_details, 'destination_url.length')) {
                alert("Please enter a Destination URL")
                return false;
            }

            if (!isValid) {
                alert(
                    "Your Destination URL is invalid.  Please fix before proceeding"
                );
                return false;
            }
        }

        if (url_type === 'webinar') {
            // keyword
            if (url_type === 'webinar' && (!_.has(link_details, 'kywd') || !_.get(link_details, 'kywd.length'))) {
                alert("Please enter a keyword for your webinar")
                return false;
            }

            // gtw_acct_id
            if (url_type === 'webinar' && !_.has(link_details, 'gtw_acct_id')) {
                alert("Please select a GoToWebinar account")
                return false
            }

            // lead identification
            if (!_.has(link_details, 'id_method')) {
                alert("Please choose how you want to identify the lead")
                return false
            }
        }
    }
    return true;
}

export const mediaValidation = (type, data) => {
    const isFieldFilled = value => {
        if(value && value.toString().length > 0) {
            return true;
        }
        return false;
    }

    switch(type) {
        case 'vendor':
            if(!isFieldFilled(data.source_name) ) {
                return 'Vendor Name is required'
            }
        break;

        case 'type':
            if(!isFieldFilled(data.medium_name) ) {
                return 'Medium Name is required'
            }
        break;

        case 'client':
            if(!isFieldFilled(data.client_name) ) {
                return 'Client Name is required'
            }
        break;

        case 'publisher':
            if(!isFieldFilled(data.client_id) ) {
                return 'Linked Client is required'
            }
            if(!isFieldFilled(data.publisher_name) ) {
                return 'Publisher Name is required'
            }
        break;

        case 'offer':
            if(!isFieldFilled(data.offer_name) ) {
                return 'Offer Name is required'
            }
            if(!isFieldFilled(data.client_id) ) {
                return 'Linked Client is required'
            }
            // if(!isFieldFilled(data.publisher_id) ) {
            //     return 'Linked Publisher is required'
            // }
            if(!isFieldFilled(data.interest_category_id) ) {
                return 'Linked Interest Category is required'
            }
        break;

        case 'InterestCategory':
            if(!isFieldFilled(data.interest_name) ) {
                return 'Interest Category Name is required'
            }
        break;

        case 'Content':
            if(!isFieldFilled(data.content_name) ) {
                return 'Content Name is required'
            }
        break;

        case 'Keyword':
            if(!isFieldFilled(data.keyword) ) {
                return 'Keyword Name is required'
            }
        break;

        case 'Affiliate':
            if(!isFieldFilled(data.affiliate_name) ) {
                return 'Affiliate Name is required'
            }
        break;

        case 'Guru':
            if(!isFieldFilled(data.guru_name) ) {
                return 'Guru Name is required'
            }
        break;
        
    }
    return true;
}

export const prefillForm = (formValues, data) => {
    // formValues.client_id = (process.env.ORGANIZATION === 'Traders Agency') ? data.clients.find(c => c.client_name ==='Traders Agency').client_id : data.clients.find(c => c.client_name ==='Legacy Research').client_id;
    // formValues.internal_content_id = null;
    // formValues.internal_affiliate_id = null;
    // formValues.internal_guru_id = null;
    // formValues.internal_term_id = null;
    // formValues.send_leads_to_email_system = 1;
    // formValues.add_incoming_leads = 1;
    // formValues.variable_cpl = 0;
    // formValues.internal_publisher_id = null;
    // formValues.internal_source_id = null;
    // formValues.interest_category_id = null;
    formValues.send_vendor_emails = 0;
    formValues.vendor_budget = 0;
    formValues.lm_cost = 0;
    formValues.client_cpl = 0;
    formValues.lm_budget = 0;

    return formValues;
}