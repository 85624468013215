import * as Yup from 'yup';

Yup.addMethod(Yup.mixed, 'defined', function (text) {
    return this.test('defined', text, value => value !== undefined)
})

export const mediaBuyValidationSchema = Yup.object().shape({
    media_buy_name: Yup.string()
        .required('Name of Media Buy is required'),
    ...(process.env.ORGANIZATION === "LeadMark") && {
        // lm_cpl: Yup.string().required('LM CPL is required'),
        internal_publisher_id: Yup.string().nullable().defined('Publisher is required')
    },
    month: Yup.string()
        .required('Month is required'),
    year: Yup.string()
        .required('Year is required'),
    internal_source_id: Yup.string().nullable().defined('Vendor is required'),
    internal_medium_id: Yup.string()
        .nullable().defined('Type is required'),
    interest_category_id: Yup.string()
        .nullable().defined('Interest Category is required'),
    internal_content_id: Yup.string()
        .nullable().defined('Content is required'),
    internal_term_id: Yup.string()
        .nullable().defined('Keyword is required'),
    internal_affiliate_id: Yup.string()
        .nullable().defined('Affiliate is required'),
    internal_guru_id: Yup.string()
        .nullable().defined('Guru is required'),
    client_id: Yup.string()
        .required('Client is required'),
    internal_offer_id: Yup.string()
        .nullable().defined('Offer is required'),
    url_type: Yup.string()
        .required('URL type is required')
})
