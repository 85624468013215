import React from "react";
import axios from "axios";

import SaveIcon from '@material-ui/icons/Save';

import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

//import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from '../Autocomplete';

const validateMail = (str) => {
    var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
    var regexp = new RegExp(expression);
    return regexp.test(str);
}

export default class FormDesigner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            offers: [],
            clients: [],
            widgets: [],
            media_buys: [],
            lpData: {
                name_internal: '',
                name_builder: '',
                description: '',
                page_builder: '',
                offer_id: (this.props.defaults && this.props.defaults.offer_id) ?  this.props.defaults.offer_id : undefined,
                client_id: (this.props.defaults && this.props.defaults.client_id) ?  this.props.defaults.client_id : undefined,
                live_page_url: '',
                page_builder_editor_url: '',
                media_buys: [],
                widgets: []
            },
            errors: []
        }


        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);
    }

    async componentDidMount() {
        const { offers } = await this.getOffers();
        const { clients } = await this.getClients();
        const visibleClients = clients.filter(c => offers.filter(o => o.client_id === c.client_id).length > 0);

        if (this.props.landingPageId) {
            const { data } = await axios.get(
                process.env.SERVER_ENDPOINT + `/landing-pages?id=${this.props.landingPageId}`
            );

            if (data.data && data.data.length > 0) {

                const item = data.data[0];
                const resp = await this.getMediaBuys(item.offer_id);
                const res = await this.getWidgets(item.offer_id);

                this.setState({
                    lpData: {
                        landing_page_id: this.props.landingPageId,
                        name_internal: item.name_internal,
                        name_builder: item.name_builder,
                        description: item.description,
                        page_builder: item.page_builder,
                        client_id: item.client_id,
                        offer_id: item.offer_id,
                        live_page_url: item.live_page_url,
                        page_builder_editor_url: item.page_builder_editor_url,
                        media_buys: item.media_buys ? JSON.parse('[' + item.media_buys + ']') : [],
                        widgets: item.widgets ? JSON.parse('[' + item.widgets + ']') : [],
                    },
                    media_buys: resp.data || [],
                    widgets: res.data || [],
                    offers,
                    clients: visibleClients,
                    loading: false
                })
            } else {
                this.setState({ offers, clients: visibleClients, loading: false })
            }
        } else {
            this.setState({ offers, clients: visibleClients, loading: false })
        }
    }
    handleChange(name) {
        return (e) => {
            const val = e.target.value;
            const data = { ...this.state.lpData, [name]: val };
            if (name === 'offer_id') {
                data.media_buys = [];
                data.widgets = [];
                this.getMediaBuys(val)
                    .then(resp => {
                        this.setState({ media_buys: resp.data || [] });
                        return this.getWidgets(val);
                    })
                    .then(resp => {
                        this.setState({ widgets: resp.data || [] });
                    })
            }
            this.setState({ lpData: data });
        }
    }

    handleMultiChange(name) {
        return (e, val) => {
            const data = { ...this.state.lpData, [name]: val };
            this.setState({ lpData: data });
        }
    }

    async getClients() {
        try {
            const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/clients")
            return data;
        } catch (error) {
            this.setState({ errors: [...this.state.errors, "Error occurred during clients fetch!"] })
        }
    }
    async getOffers() {
        try {
            const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/offers")
            return data;
        } catch (error) {
            this.setState({ errors: [...this.state.errors, "Error occurred during offers fetch!"] })
        }
    }
    async getMediaBuys(offer_id) {
        try {
            const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/mediaBuys?offers%5B0%5D=" + offer_id)
            return data;
        } catch (error) {
            this.setState({ errors: [...this.state.errors, "Error occurred during media_buys fetch!"] })
            return [];
        }
    }
    async getWidgets(offer_id) {
        try {
            const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/widgets?offer=" + offer_id)
            return data;
        } catch (error) {
            this.setState({ errors: [...this.state.errors, "Error occurred during media_buys fetch!"] })
            return [];
        }
    }

    save(e) {
        const form = this.state.lpData;
        if (form.name_internal === '') {
            return this.setState({ error: 'name' });
        }
        if (form.client_id === '') {
            return this.setState({ error: 'client' });
        }
        if (form.offer_id === '') {
            return this.setState({ error: 'offer' });
        }
        if (form.page_builder === '') {
            return this.setState({ error: 'builder' });
        }
        if (form.live_page_url === '') {
            return this.setState({ error: 'live_page' });
        }
        if (!validateMail(form.live_page_url)) {
            return this.setState({ error: 'live_page_url' });
        }
        if (form.page_builder_editor_url !== '' && !validateMail(form.page_builder_editor_url)) {
            return this.setState({ error: 'page_builder_editor_url' });
        }

        axios.post(process.env.SERVER_ENDPOINT + "/landing-pages", this.state.lpData).then(e => {
            if(this.props.onSave) {
                this.props.onSave(e.data.landing_page_id);
            } else {
                window.location.href = "/media-planner/landing-page-manager"
            }
        }).catch(e => console.log(e));
    }

    cancel(e) {
        if(this.props.onCancel) {
            this.props.onCancel();
        } else {
            e.preventDefault();
            return window.location.href = "/media-planner/media-planner/landing-page-manager";
        }
    }

    render() {
        const form = this.state.lpData;
        const error = this.state.error;

        const offers = form.client_id ? this.state.offers.filter(o => o.client_id === form.client_id) : this.state.offers;

        //const media_buys_to_select = type ? this.state.media_buys.filter(m => types[this.props.type].includes(m.url_type)) : [];
        const media_buys_to_select = this.state.media_buys || [];
        const widgets_to_select = this.state.widgets || [];

        return this.state.loading ?
            <div style={{ width: '100%', height: '300px', display: 'flex' }}>
                <CircularProgress style={{ margin: '130px auto 0' }} />
            </div> :
            <Grid container spacing={0} className="widget_container">
                <Grid item xs={12} container spacing={0} className="widget_form">
                    <Grid item xs={4}>
                        <InputLabel>Landing Page Title/Name - Internal *</InputLabel>
                        <TextField
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={form.name_internal}
                            onChange={this.handleChange('name_internal')}
                        />
                        {error && error === 'name' && <p className="error">Name is required</p>}
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Landing Page Title/Name - Page Builder</InputLabel>
                        <TextField
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={form.name_builder}
                            onChange={this.handleChange('name_builder')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Landing Page Description</InputLabel>
                        <TextField
                            multiline
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            rowsMax="4"
                            value={form.description}
                            onChange={this.handleChange('description')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Landing Page Builder *</InputLabel>
                        <Select
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={form.page_builder || ''}
                            onChange={this.handleChange('page_builder')}>
                            <MenuItem value="LeadPages">LeadPages</MenuItem>
                            <MenuItem value="ClickFunnels ">ClickFunnels</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        {error && error === 'builder' && <p className="error">Landing Page Builder is required</p>}
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Client *</InputLabel>
                        <Select
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={form.client_id || ''}
                            onChange={this.handleChange('client_id')}>
                            {this.state.clients.map((client, idx) => (
                                <MenuItem value={client.client_id} key={'client_' + idx}>{client.client_name}</MenuItem>
                            ))}
                        </Select>
                        {error && error === 'client' && <p className="error">Client is required</p>}
                    </Grid>
                    <Grid item xs={4}>
                        {form.client_id ? <>
                            <InputLabel>Offer *</InputLabel>
                            <Select
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={form.offer_id || ''}
                                onChange={this.handleChange('offer_id')}>
                                {offers.map((offer, idx) => (
                                    <MenuItem value={offer.offer_id} key={'offer_' + idx}>{offer.offer_name}</MenuItem>
                                ))}
                            </Select>
                            {error && error === 'offer' && <p className="error">Offer is required</p>}
                        </> : null
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Live Page URL *</InputLabel>
                        <TextField required
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={form.live_page_url}
                            onChange={this.handleChange('live_page_url')}
                        />
                        {error && error === 'live_page' && <p className="error">Live Page URL is required</p>}
                        {error && error === 'live_page_url' && <p className="error">Live URL is not properly formatted</p>}
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel>Landing Page Builder Editor URL</InputLabel>
                        <TextField
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={form.page_builder_editor_url}
                            onChange={this.handleChange('page_builder_editor_url')}
                        />
                        {error && error === 'page_builder_editor_url' && <p className="error">Editor URL is not properly formatted</p>}
                    </Grid>
                    {form.offer_id && widgets_to_select.length > 0 &&
                        <Grid item xs={4}>
                            <InputLabel>Linked Forms & Widgets</InputLabel>
                            <Autocomplete
                                multiple
                                value={form.widgets}
                                options={widgets_to_select.map(m => m.widget_id)}
                                getOptionLabel={(option) => {
                                    const w = widgets_to_select.find(m => option === m.widget_id);
                                    return w ? w.name + '::' + w.type : option
                                }}
                                filterSelectedOptions
                                disableCloseOnSelect
                                onChange={this.handleMultiChange('widgets')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="multiselect..."
                                    />
                                )}
                            />
                        </Grid>
                    }
                    {form.offer_id && media_buys_to_select.length > 0 &&
                        <Grid item xs={4}>
                            <InputLabel>Linked Media buys</InputLabel>
                            <Autocomplete
                                multiple
                                value={form.media_buys}
                                options={media_buys_to_select.map(m => m.media_buy_id)}
                                getOptionLabel={(option) => {
                                    const mb = media_buys_to_select.find(m => option === m.media_buy_id);
                                    return mb ? mb.media_buy_id + ' - ' + mb.media_buy_name : option
                                }}
                                filterSelectedOptions
                                disableCloseOnSelect
                                onChange={this.handleMultiChange('media_buys')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="multiselect..."
                                    />
                                )}
                            />
                        </Grid>
                    }

                    <Grid item xs={12} className="widget_actions">
                        <button
                            style={{ marginLeft: 'auto' }}
                            onClick={this.cancel.bind(this)}
                            className="btn white">Cancel</button>
                        <button
                            onClick={this.save.bind(this)}
                            style={{ marginLeft: '10px' }}
                            className="btn">Save</button>
                    </Grid>
                </Grid>
            </Grid>
    }

}