import { has, get, head, filter } from "lodash";

export const getPath = () => {
  if (typeof window === "object") {
    if (has(organization, window.location.host)) {
      console.log("found", window.location.host);
      return window.location.host;
    }
  }
  console.log("not found", "default");
  // return "lmcentral.leadmark.com";
  // return "tacentral.tradersagency.com";
  return "default";
};

const api_link = `https://api.leadmark.com/leads?email=<email>&fName=<firstName>&lName=<lastName>&mbid=<%=media_buy_id%>&ccid=<%=client_campaign_id%>&siteId=<siteId>`;

export const organization = {
  "lmcentral.leadmark.com": {
    name: "LeadMark",
    logo:
      "http://ambid-backups.s3.amazonaws.com/screenshots/sales-agency/lm_logo.png",
    background: "white",
    disabled_features: [],
    api_link
  },
  "localhost:8000": {
    name: "LeadMark",
    logo:
      "http://ambid-backups.s3.amazonaws.com/screenshots/sales-agency/lm_logo.png",
    background: "white",
    disabled_features: [],
    api_link
  },
  "localhost:5000": {
    name: "LeadMark",
    logo:
      "http://ambid-backups.s3.amazonaws.com/screenshots/sales-agency/lm_logo.png",
    background: "white",
    disabled_features: [],
    api_link
  },
  "tacentral.tradersagency.com": {
    name: "Traders Agency",
    logo:
      "http://ambid-backups.s3.amazonaws.com/screenshots/sales-agency/TA_Title_Long.png",
    background: "black",
    disabled_features: [
      "SEND_VENDOR_EMAIL",
      "SA_CPL",
      "SA_COST",
      "MARGIN",
      "PUBLISHER",
      "POST_BACK_URL",
      "EXTRA_FIELDS"
    ],
    api_link
  }
  // default: {
  //   name: "Sales Agency",
  //   logo:
  //     "http://ambid-backups.s3.amazonaws.com/screenshots/sales-agency/sa_logo.png",
  //   background: "black",
  //   disabled_features: [],
  //   api_link
  // }
};

export const getOrganization = () => {
  return organization[getPath()];
};

export const getMonth = month => {
  const mo = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December"
  };

  return mo[month];
};

export const isLinkInternal = (mainFormDetails, deps) => {
  const { internal_medium_id } = mainFormDetails;
  const { types } = deps;
  const type_info = head(filter(types, type => type.medium_id === internal_medium_id))
  return get(type_info, 'type_id') === 2
}

export const getClientName = mainFormDetails => {
  const { clients, client_id } = mainFormDetails;
  const client_info = head(filter(clients, client => client.client_id === client_id ))
  return get(client_info, 'client_name', false)
}