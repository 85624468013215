import React from "react"
import _ from "lodash"
import MediaBuyRequestProvider from './provider';
import { url_types } from './provider';
import { generateMediaBuyName, resolveStatusName, customValidation, prefillForm } from './helpers';
import { CircularProgress, Grid, TextField, Checkbox, Input, FormControlLabel, Tooltip } from "@material-ui/core";
import { AutocompleteSingle, MBTextField } from "./fields";
import qString from "../../../scripts/qString";
import { AssetsPopup } from "../Dashboards/AssetsPopup";
import NumberFormatCustom from "../Miscellaneous/NumberFormatCustom"
import NumberFormat from "react-number-format";
import MBPopups from "./popups";
import { CTOLinkOptions, WebinarLinkOptions, LandingPageLinkOptions, APILinkOptions, ODWLinkOptions } from "./Forms/linkOptions";
import { Formik } from "formik";
import { mediaBuyValidationSchema } from "../../helpers/validations";
import moment from 'moment';
import { withStyles } from "@material-ui/styles";
import { isLinkInternal } from "../../../scripts/global";
const gridStyles = {
    width: '33%',
    padding: '4px 30px'
}

const NEW_MEDIA_BUY = {
    status: 'pending_approval',
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    variable_cpl: 0
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

class MediaBuyRequestForm extends React.Component {
    service = MediaBuyRequestProvider
    popups = React.createRef();
    landingPage = React.createRef();
    apiLinkOptions = React.createRef();
    ctoLinkOptions = React.createRef();

    state = {
        newlyCreated: false,
        errors: [],
        readonly: true,
        loading: true,
        deps: {},
        data: {},
        dataPristine: {}
    }

    async componentDidMount() {
        const mbid = _.get(qString, "?id", "");
        if (mbid) {
            let mediaBuyResponse = await this.service.getMediaBuyById(mbid);
            
            let data = _.head(_.get(mediaBuyResponse, "data.media_buys"));
            this.setState({
                ...this.state,
                data: data,
                dataPristine: {...data}
            })
        } else {
            this.setState({
                ...this.state,
                readonly: false,
                data: Object.assign({}, NEW_MEDIA_BUY)
            })
        }

        this.service.loadDepsGroup().then(async data => {
            let formValues = {...this.state.data};
            if(!mbid) {
                formValues = prefillForm(formValues, data);
            }
            let publishers = await this.service.getPublishers(formValues.client_id)
            let offers = await this.service.getOffers(formValues.client_id, formValues.internal_publisher_id)
            this.setState({...this.state, data: formValues, deps: {...data, offers: offers, publishers: publishers}, loading: false})
        })
    }

    async singleChange(value, type) {
        let data = {...this.state.data};
        data[type] = value !== null ? value.value ? value.value : value : null;
        switch(type) {
            case 'internal_source_id':
            case 'internal_medium_id':
            case 'interest_category_id':
            case 'eid':
                for(let i in data) {
                    if(data[i] && typeof(data[i]) === 'object' && data[i].link_is_internal !== undefined) {
                        setTimeout(() => {
                            data[i].link_is_internal = isLinkInternal(data, this.state.deps)
                        })
                    }
                }
                data.media_buy_name = generateMediaBuyName({
                    ...data,
                    vendors: this.state.deps.vendors,
                    offers: this.state.deps.offers, 
                    types: this.state.deps.types
                });
            break;

            case 'internal_publisher_id':
                (async () => {
                    let deps = {...this.state.deps};
                    let offers = await this.service.getOffers(data.client_id, data[type])
                    data.internal_offer_id = undefined;
                    this.setState({
                        ...this.state,
                        deps: {...deps, offers}
                    })
                })()
            break;

            case 'client_id':
                let foundClient = this.service.getClientById(this.state.data.client_id, this.state.deps.clients || []) 
                let eidRequired = (foundClient.client_name === 'Legacy Research');
                if(!eidRequired) {
                    delete data['eid']
                }
                data.media_buy_name = generateMediaBuyName({
                    ...data,
                    vendors: this.state.deps.vendors,
                    offers: this.state.deps.offers, 
                    types: this.state.deps.types
                });
                let publishers = await this.service.getPublishers(data[type])
                let offers = await this.service.getOffers(data[type], data.internal_publisher_id)
                let deps = {...this.state.deps};
                data.internal_publisher_id = null;
                data.internal_offer_id = undefined;
                this.setState({
                    ...this.state,
                    deps: {...deps, publishers, offers}
                })
            break;

            case 'status':
                if(this.state.data.send_vendor_emails == 1 
                    && (data[type] == 'approved' || data[type] == 'approved_paused')
                    && this.state.dataPristine.status !== 'approved' && this.state.dataPristine.status !== 'approved_paused'
                    && (process.env.DISABLED_FEATURES.split(",")).indexOf(
                        "SEND_VENDOR_EMAIL"
                    ) === -1
                    ) {
                    this.service.sendEmail(data);
                }
            break;
            case 'internal_offer_id':
                data.media_buy_name = generateMediaBuyName({
                    ...data,
                    vendors: this.state.deps.vendors,
                    offers: this.state.deps.offers, 
                    types: this.state.deps.types
                });
                const getWorkflowId = (offer_id) => {
                    const offer = _.head(
                        _.filter(
                            this.state.deps.offers,
                            itm => itm.offer_id === offer_id
                        )
                    );
                    return _.get(offer, 'workflow_id')
                }
                // let offer = this.state.deps.offers.find(offer => offer.offer_id === data[type]);
                // if(offer && offer.interest_category_id > 0) {
                //     data['interest_category_id'] = offer.interest_category_id;
                // }

                data.workflow_id = getWorkflowId(data[type]);
                setTimeout(() => {
                    if(this.landingPage.current !== null) {
                        this.landingPage.current.loadOptions();
                    }
                    if(this.apiLinkOptions.current !== null) {
                        this.apiLinkOptions.current.generatePostBackURL();
                    }
                    if(this.ctoLinkOptions.current !== null) {
                        this.ctoLinkOptions.current.generatePostBackURL();
                    }
                    
                })
            break;
            case 'workflow_id':
                if(data[type] && typeof(data[type]) === 'string') {
                    data[type] = data[type].replace(/\D/g,'');
                }
            break;
        }
        this.setState({
            ...this.state,
            data: data
        })
    }
    toggleMode() {
        this.setState({
            ...this.state,
            readonly: !this.state.readonly
        })
    }

    showAssets(data, e) {
        if(e) e.preventDefault();
        let foundClient = this.service.getClientById(this.state.data.client_id, this.state.deps.clients || []) 
        if(foundClient) {
            data.client = foundClient.client_name
        }
        this.setState({
            showAssetsPopup: true,
            data: data,
        })
    }

    cancel(e) {
        e.preventDefault();
        if(!this.state.data.media_buy_id) return window.location.href = "/media-planner/";
        if(this.landingPage.current !== null) {
            this.landingPage.current.loadOptions();
        }
        this.setState({
            ...this.state,
            data: this.state.dataPristine,
            readonly: !this.state.readonly
        })
    } 

    async save(e) {
        let foundClient = this.service.getClientById(this.state.data.client_id, this.state.deps.clients || []) 
        let eidRequired = (foundClient.client_name === 'Legacy Research');

        if (!customValidation({...this.state.data, eidRequired: eidRequired})) return false
        this.setState({
            ...this.state,
            loading: true
        })
        const data = this.state.data;

        const { internal_offer_id, url_type } = data;
        let offers = this.state.deps.offers;
        const { offer_name } = _.head(
            _.filter(offers, offer => offer.offer_id === internal_offer_id)
        );
        const formValues = _.omit({
            ...this.state.data,
            // api_url_template,
            offer_name,
            status: "pending_approval"
            // url_type: url_type.label
        }, ['client','creator_name']);

        if (data.media_buy_id) {
            this.service.updateMediaBuy(formValues).then(response => {
                this.setState({
                    ...this.state,
                    loading: false,
                    readonly: true,
                    dataPristine: {...data}
                })
            });

        } else {
            this.service.createMediaBuy(formValues).then(response => {
                let combined = {...this.state.data, ...response.data};
                let foundClient = this.service.getClientById(combined.client_id, this.state.deps.clients || []) 
                
                if(foundClient) {
                    combined.client = foundClient.client_name
                }

                this.setState({
                    ...this.state,
                    loading: false,
                    readonly: true,
                    dataPristine: {...response.data},
                    data: combined,
                    showAssetsPopup: true,
                    newlyCreated: true
                });
            });
        }
    }

    onPopupUpdate(data, deps) {
        this.setState({
            ...this.state, 
            data: {...this.state.data, ...data},
            deps: {...this.state.deps, ...deps}
        }, () => {
            try {
                this.singleChange(Object.values(data)[0], Object.keys(data)[0])
            }
            catch(err) {

            }
        })
    }

    updateFormDetails(key, value) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [key]: value
            },
        }))
    }

    closeAssetsPopup() {
        if(this.state.newlyCreated) {
            window.location.href = '/media-planner/';
        } else {
            this.setState({
                ...this.state,
                showAssetsPopup: false
            })
        }
    }

    render() {
        let foundClient = this.service.getClientById(this.state.data.client_id, this.state.deps.clients || []) 
        
        const sourceFilled = this.state.data.internal_source_id > 0 || this.state.data.internal_source_id === null;
        const mediumFilled = this.state.data.internal_medium_id > 0 || this.state.data.internal_medium_id === null;
        const clientFilled = this.state.data.client_id > 0;
        const isLegacy = foundClient.client_name === 'Legacy Research';
        const publisherFilled = (process.env.ORGANIZATION === 'Traders Agency') ? true : (this.state.data.internal_publisher_id > 0 || this.state.data.internal_publisher_id === null);
        const offerFilled = this.state.data.internal_offer_id > 0 || this.state.data.internal_offer_id === null;
        const categoryFilled = this.state.data.interest_category_id > 0 || this.state.data.interest_category_id === null;
        const contentFilled = this.state.data.internal_content_id > 0 || this.state.data.internal_content_id === null;
        const termFilled = this.state.data.internal_term_id > 0 || this.state.data.internal_term_id === null;
        const affiliateFilled = this.state.data.internal_affiliate_id > 0 || this.state.data.internal_affiliate_id === null;
        const guruFilled = this.state.data.internal_guru_id> 0 || this.state.data.internal_guru_id === null;
        let eidCond = ((foundClient.client_name === 'Legacy Research' && this.state.data.eid && this.state.data.eid.length > 0) || (foundClient.client_name !== 'Legacy Research'));

        const firstGroupFilled = sourceFilled && mediumFilled && clientFilled && publisherFilled && offerFilled && categoryFilled && contentFilled && termFilled && affiliateFilled && guruFilled && eidCond;
        
        return (      
            <Formik 
                enableReinitialize
                initialValues={{...this.state.data}}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, { setSubmitting });
                }}
                validationSchema={mediaBuyValidationSchema}>
                {props => {
                        const {
                            errors,
                            dirty,
                            handleSubmit,isValid
                        } = props; 
                return <form style={{width: '98.8vw'}} onSubmit={handleSubmit}>
                <MBPopups onUpdate={(data, deps) => this.onPopupUpdate(data, deps)} deps={this.state.deps} data={this.state.data} ref={this.popups} />
                <div className="mb_header">
                    <h3>{this.state.data.media_buy_id ? 'Edit' : 'Create'} Media Buy Request</h3>

                    {this.state.readonly ?
                    <>
                        <button className="edit__btn" aria-haspopup="true" onClick={() => this.toggleMode()}>
                            Edit
                        </button>
                        <button onClick={e => this.showAssets(this.state.data, e)} className="assets__btn" style={{marginLeft: '10px'}} aria-haspopup="true">
                            Get Assets
                        </button>
                    </>
                    : null}
                </div>
                <Grid container style={{
                    padding: '0',
                    boxShadow: '0 0 3px rgba(0,0,0,0.1)'
                }}>
                    {this.state.showAssetsPopup && <AssetsPopup data={this.state.data} closePopup={() => this.closeAssetsPopup()} /> }
                    {this.state.loading ? <CircularProgress style={{margin: '100px auto'}} /> : 
                    <>
                        <Grid style={{display: 'flex', backgroundColor: 'rgb(240, 245, 248)', width: '100%', padding: '10px', margin: '0 0 10px 0'}}>
                            <Grid className="report_filter" style={{width: '35%', padding: '4px 20px'}}>
                                <LightTooltip title="The Media Buy Name is what displays in reports, etc. It should be a friendly, descriptive name that will make reporting more meaningful."><span className="label">Media Buy Name</span></LightTooltip>
                                {!this.state.readonly ? 
                                <Input onChange={value => this.singleChange(value.target.value, 'media_buy_name')} disabled={
                                    !(sourceFilled && mediumFilled && clientFilled && publisherFilled && offerFilled && categoryFilled && contentFilled && termFilled && affiliateFilled && guruFilled && eidCond)
                                } value={this.state.data.media_buy_name} style={{ width: '100%', border: '0 none', backgroundColor: 'transparent'}} />
                                : <span>{this.state.data.media_buy_name}</span> }
                            </Grid>

                            <Grid className="report_filter" style={{width: '21.6%', padding: '4px 20px'}}>
                                <span className="label">Year</span>
                                <AutocompleteSingle
                                    error={errors.year}
                                    readonly={this.state.readonly}
                                    onChange={value => this.singleChange(value, 'year')}
                                    value={this.state.data.year}
                                    options={this.state.deps.yearOptions}
                                    labelField="label"
                                    valueField="value"
                                />
                            </Grid>

                            <Grid className="report_filter" style={{width: '21.6%', padding: '4px 20px'}}>
                            <span className="label">Month</span>
                                <AutocompleteSingle
                                    error={errors.month}
                                    required
                                    readonly={this.state.readonly}
                                    onChange={value => this.singleChange(value, 'month')}
                                    value={this.state.data.month}
                                    options={this.state.deps.monthOptions}
                                    labelField="label"
                                    valueField="value"
                                />
                            </Grid>
                            <Grid className="report_filter" style={{width: '21.6%', padding: '4px 20px'}}>
                            <LightTooltip title="The Status indicates in what stage a media buy is in, and whether or not it is currently receiving traffic."><span className="label">Status</span></LightTooltip>
                                <AutocompleteSingle
                                    error={errors.status}
                                    readonly={this.state.readonly}
                                    customReadonly={true}
                                    onChange={value => this.singleChange(value, 'status')}
                                    value={this.state.data.status}
                                    options={this.state.deps.statusOptions}
                                                                        labelField="label"
                                    valueField="value"
                                />

                                {this.state.readonly ? 
                                    <div className={`mb_status ${this.state.data.status}`}>
                                        {resolveStatusName(this.state.data.status, this.state.deps.statusOptions) }
                                    </div>
                                : null}
                            </Grid>
                        </Grid>
 
                        <Grid className="report_filter" style={gridStyles}>
                        <LightTooltip title="Please specify the vendor or source that will be sending traffic to this media buy. Examples are Google, Facebook, AfterOffers, etc."><span className="label">Vendor</span></LightTooltip>
                            <AutocompleteSingle
                                emptyLabel="No Source"
                                error={errors.internal_source_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'internal_source_id')}
                                value={this.state.data.internal_source_id}
                                options={this.state.deps.vendors}
                                labelField="source_name"
                                valueField="source_id"
                                createLabel={"Create New Vendor"}
                                onEdit={data => this.popups.current.show('showVendorForm', data)}
                                onCreate={() => this.popups.current.show('showVendorForm', {}) }
                            />
                        </Grid>

                        {sourceFilled ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the type of traffic that will be sent to this media buy. Examples are Pay Per Click, Co-Registration, Email - Internal, etc."><span className="label">Type/Medium</span></LightTooltip>
                            <AutocompleteSingle
                                emptyLabel="No Medium"
                                error={errors.internal_medium_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'internal_medium_id')}
                                value={this.state.data.internal_medium_id}
                                options={this.state.deps.types}
                                labelField="medium_name"
                                valueField="medium_id"
                                createLabel={"Create New Type/Medium"}
                                onEdit={data => this.popups.current.show('showTypeForm', data)}
                                onCreate={() => this.popups.current.show('showTypeForm', {}) }
                            />
                        </Grid>
                        : null}

                        {sourceFilled && mediumFilled ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the client for which traffic will be generated with this media buy."><span className="label">Client</span></LightTooltip>
                            <AutocompleteSingle
                                error={errors.client_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'client_id')}
                                value={this.state.data.client_id}
                                options={this.state.deps.clients}
                                labelField="client_name"
                                valueField="client_id"
                                createLabel={"Create New Client"}
                                onEdit={data => this.popups.current.show('showClientForm', data)}
                                onCreate={() => this.popups.current.show('showClientForm', {}) }
                            />
                        </Grid>
                        : null}

                        {process.env.ORGANIZATION !== 'Traders Agency' && sourceFilled && mediumFilled && clientFilled ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the client’s specific publisher for which traffic will be generated with this media buy. Examples are Bonner & Partners, Palm Beach Letter, etc. Please note that most clients DO NOT have separate publishers, so in most cases, the submitted value should be “No Publisher”."><span className="label">Publisher</span></LightTooltip>
                            <AutocompleteSingle
                                emptyLabel="No Publisher"
                                error={errors.internal_publisher_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'internal_publisher_id')}
                                value={this.state.data.internal_publisher_id}
                                options={this.state.deps.publishers}
                                labelField="publisher_name"
                                valueField="publisher_id"
                                createLabel={"Create New Publisher"}
                                onEdit={data => this.popups.current.show('showPublisherForm', data)}
                                onCreate={() => this.popups.current.show('showPublisherForm', {client_id: this.state.data.client_id}) }
                            />
                        </Grid>
                        : null}

                        {sourceFilled && mediumFilled && clientFilled && isLegacy ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the Legacy effort ID under which traffic from this media buy will be tracked. An example entry to this field could be MKT123456."><span className="label">EID</span></LightTooltip>
                            <MBTextField
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'eid')}
                                value={this.state.data.eid}
                            />
                        </Grid>
                        : null}

                        {sourceFilled && mediumFilled && clientFilled && publisherFilled && eidCond ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the offer which will be promoted with this media buy."><span className="label">Offer</span></LightTooltip>
                            <AutocompleteSingle
                                error={errors.internal_offer_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'internal_offer_id')}
                                value={this.state.data.internal_offer_id}
                                options={this.state.deps.offers}
                                labelField="offer_name"
                                valueField="offer_id"
                                createLabel={"Create New Offer"}
                                onEdit={data => this.popups.current.show('showOfferForm', data)}
                                onCreate={() => this.popups.current.show('showOfferForm', {interest_category_id: this.state.data.interest_category_id, publisher_id: this.state.data.internal_publisher_id, client_id: this.state.data.client_id}) }
                            />
                        </Grid>
                        : null}

                        {sourceFilled && mediumFilled && clientFilled && publisherFilled && offerFilled && eidCond ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the interest category that most closely relates to the offer which will be promoted with this media buy. The purpose of an interest category is to provide an additional method of segmenting incoming leads. If this level of tracking is not required or applicable, “No Interest Category” can be selected."><span className="label">Interest Category</span></LightTooltip>
                            <AutocompleteSingle
                                emptyLabel="No Interest Category"
                                error={errors.interest_category_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'interest_category_id')}
                                value={this.state.data.interest_category_id}
                                options={this.state.deps.interestCategories}
                                labelField="interest_name"
                                valueField="interest_category_id"
                                createLabel={"Create New Interest Category"}
                                onEdit={data => this.popups.current.show('showInterestCategoryForm', data)}
                                onCreate={() => this.popups.current.show('showInterestCategoryForm', {}) }
                            />
                        </Grid>
                        : null}


                        {sourceFilled && mediumFilled && clientFilled && publisherFilled && offerFilled && categoryFilled && eidCond ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the content which will generate the traffic for this media buy. Think of content as similar to the “utm_content” tracking parameter. The purpose of the content selection is to provide a method of tracking traffic performance down to the level of a specific ad, email, or other piece of content. If this level of tracking is not required or applicable, “No Content” can be selected."><span className="label">Content</span></LightTooltip>
                            <AutocompleteSingle
                                emptyLabel="No Content"
                                error={errors.internal_content_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'internal_content_id')}
                                value={this.state.data.internal_content_id}
                                options={this.state.deps.contents}
                                labelField="content_name"
                                valueField="content_id"
                                createLabel={"Create New Content"}
                                onEdit={data => this.popups.current.show('showContentForm', data)}
                                onCreate={() => this.popups.current.show('showContentForm', {}) }
                            />
                        </Grid>
                        : null}

                        {sourceFilled && mediumFilled && clientFilled && publisherFilled && offerFilled && categoryFilled && contentFilled && eidCond ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the keyword which will generate the traffic for this media buy. Think of a keyword as similar to the “utm_term” tracking parameter. The purpose of the keyword selection is to provide a method of tracking traffic performance down to the specific keyword level. If the type of media buy being created does not relate to paid search, organic search, or another type of keyword driven traffic source, “No Keyword” can be selected."><span className="label">Keyword</span></LightTooltip>
                            <AutocompleteSingle
                                emptyLabel="No Keyword"
                                error={errors.internal_term_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'internal_term_id')}
                                value={this.state.data.internal_term_id}
                                options={this.state.deps.keywords}
                                labelField="keyword"
                                valueField="keyword_id"
                                createLabel={"Create New Keyword"}
                                onEdit={data => this.popups.current.show('showKeywordForm', data)}
                                onCreate={() => this.popups.current.show('showKeywordForm', {}) }
                            />
                        </Grid>
                        : null}


                    {sourceFilled && mediumFilled && clientFilled && publisherFilled && offerFilled && categoryFilled && contentFilled && termFilled && eidCond ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the affiliate to which traffic and sales for this media buy will be attributed. The purpose of the affiliate selection is to provide a method of tracking traffic performance down to the specific affiliate. If the type of media buy being created should not be tracked back to an affiliate, “No Affiliate” can be selected."><span className="label">Affiliate</span></LightTooltip>
                            <AutocompleteSingle
                                emptyLabel="No Affiliate"
                                error={errors.internal_affiliate_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'internal_affiliate_id')}
                                value={this.state.data.internal_affiliate_id}
                                options={this.state.deps.affiliates}
                                labelField="affiliate_name"
                                valueField="affiliate_id"
                                createLabel={"Create New Affiliate"}
                                onEdit={data => this.popups.current.show('showAffiliateForm', data)}
                                onCreate={() => this.popups.current.show('showAffiliateForm', {}) }
                            />
                        </Grid>
                        : null}

                        {sourceFilled && mediumFilled && clientFilled && publisherFilled && offerFilled && categoryFilled && contentFilled && termFilled && affiliateFilled && eidCond ?
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify the guru for which traffic from this media buy will be generated. The purpose of the guru selection is to provide a method of tracking traffic performance down to the specific guru which is being promoted. If this level of tracking is not required or applicable, “No Guru” can be selected."><span className="label">Guru</span></LightTooltip>
                            <AutocompleteSingle
                                emptyLabel="No Guru"
                                error={errors.internal_guru_id}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'internal_guru_id')}
                                value={this.state.data.internal_guru_id}
                                options={this.state.deps.gurus}
                                labelField="guru_name"
                                valueField="guru_id"
                                createLabel={"Create New Guru"}
                                onEdit={data => this.popups.current.show('showGuruForm', data)}
                                onCreate={() => this.popups.current.show('showGuruForm', {}) }
                            />
                        </Grid>
                        : null}

                        {firstGroupFilled ?
                        <>
                        <hr style={{ margin: '25px 40px', opacity: '0.3', width: '100%' }} />

                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title="Please specify whether or not incoming leads from this media buy should be added to LeadMark’s email platform. The agreement LeadMark has with the associated client, along with other considerations, should determine the option selected here."><span className="label">Send Leads to Email Platform?</span></LightTooltip><br />
                            {this.state.readonly ?
                                <>
                                {this.state.data.send_leads_to_email_system === 1 ? 'Yes': 'No'}
                                </>
                            :
                            <>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={this.state.data.send_leads_to_email_system === 1}
                                    onChange={e => {
                                        this.updateFormDetails('send_leads_to_email_system', 1)
                                    }}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            } label="Yes" />
                            
                            <FormControlLabel control={
                                <Checkbox
                                    checked={this.state.data.send_leads_to_email_system === 0}
                                    onChange={e => {
                                        this.updateFormDetails('send_leads_to_email_system', 0)
                                    }}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            } label="No" />
                            </>
                            }

                            {this.state.data.send_leads_to_email_system === 1 ?
                            <>
                            <br /><br />
                            <LightTooltip title="Please specify whether or not incoming leads from this media buy should be added to a particular email series/workflow in LeadMark’s email platform. If “No” is selected, then leads will be added to LeadMark’s email platform without being added to a specific email series/workflow."><span className="label">Add Incoming Leads to Workflow</span></LightTooltip><br />
                            {this.state.readonly ?
                                <>
                                {this.state.data.add_incoming_leads === 1 ? 'Yes': 'No'}
                                </>
                            :
                                <>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={this.state.data.add_incoming_leads === 1}
                                        onChange={e => {
                                            this.updateFormDetails('add_incoming_leads', 1)
                                        }}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                } label="Yes" />

                                <FormControlLabel control={
                                    <Checkbox
                                        checked={this.state.data.add_incoming_leads === 0}
                                        onChange={e => {
                                            this.updateFormDetails('add_incoming_leads', 0)
                                        }}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                } label="No" />
                                </>
                            }
                            </>
                            :null}
                            <br /><br />
                            {this.state.data.add_incoming_leads === 1 && this.state.data.send_leads_to_email_system === 1 ?
                            <>
                                <LightTooltip title="Please specify the Iterable Workflow ID to which traffic from this media buy should be added. This value will determine which email series new leads from this media buy will receive, subject to the specific rules of the specified workflow."><span className='label'>Worklflow ID</span></LightTooltip>
                                <MBTextField
                                    readonly={this.state.readonly}
                                    onChange={value => this.singleChange(value, 'workflow_id')}
                                    value={this.state.data.workflow_id}
                                />
                                <br /><br />
                            </>
                            : null}
                            {(process.env.DISABLED_FEATURES.split(',').indexOf('SEND_VENDOR_EMAIL') === -1) && typeof(this.state.data.send_leads_to_email_system) !=='undefined' && typeof(this.state.data.add_incoming_leads) !=='undefined' &&

                                <>
                                <span className="label">Send Link via Email to Vendor?</span><br />
                                {this.state.readonly ?
                                    <>
                                    {this.state.data.send_vendor_emails === 1 ? 'Yes': 'No'}
                                    </>
                                :
                                <>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={this.state.data.send_vendor_emails === 1}
                                        onChange={e => {
                                            this.updateFormDetails('send_vendor_emails', 1)
                                        }}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                } label="Yes" />

                                <FormControlLabel control={
                                    <Checkbox
                                        checked={this.state.data.send_vendor_emails === 0}
                                        onChange={e => {
                                            this.updateFormDetails('send_vendor_emails', 0)
                                        }}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                } label="No" />
                                </>
                                }
                                </>
                            }
                        </Grid>

                        {typeof(this.state.data.send_leads_to_email_system) !=='undefined' ? 
                        <>
                        <Grid className="report_filter" style={gridStyles}>
                            <>
                            <LightTooltip title="Please specify the budget amount that will be attributed to the selected vendor. This value represents the budget to which the vendor will have to adhere while delivering traffic for this media buy. This is also the value that will be displayed in the email to the vendor that includes their link."><span className="label">Vendor Budget</span></LightTooltip>
                            <MBTextField
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'vendor_budget')}
                                value={this.state.data.vendor_budget}
                                inputProps={{
                                    inputComponent: NumberFormatCustom
                                }}
                            />
                            </>
                            
                            {process.env.ORGANIZATION === "LeadMark" ?
                            <>
                                <LightTooltip title={`Please specify the budget amount provided by the client that LeadMark must adhere to while delivering traffic to this media buy. This amount represents the maximum amount that the client has agreed to pay ${process.env.ORGANIZATION} to generate leads for this media buy.`}>
                                    <span style={{marginTop: '20px'}} className="label">{process.env.ORGANIZATION === "LeadMark" ? 'LeadMark' : 'TA'} Budget</span></LightTooltip>
                                <MBTextField
                                    readonly={this.state.readonly}
                                    onChange={value => this.singleChange(value, 'lm_budget')}
                                    value={this.state.data.lm_budget}
                                    inputProps={{
                                        inputComponent: NumberFormatCustom
                                    }}
                                />
                                <LightTooltip title={`Please specify the cost per lead to which the client has agreed to pay LeadMark for this media buy.`}><span className="label">Client CPL</span></LightTooltip>
                                <MBTextField
                                    readonly={this.state.readonly}
                                    onChange={value => this.singleChange(value, 'client_cpl')}
                                    value={this.state.data.client_cpl}
                                    inputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </>
                            : null}

                            <LightTooltip title={`Please specify whether ${process.env.ORGANIZATION}’s cost per lead for this media buy will be fixed or variable.`}><span style={{marginTop: '20px'}} className='label'>CPL Type</span></LightTooltip>
                            {this.state.readonly ?
                                <>
                                
                                {this.state.data.variable_cpl === 1 ? 'Variable': 'Fixed'}
                                </>
                            :
                                <>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={this.state.data.variable_cpl === 1}
                                        onChange={e => {
                                            this.updateFormDetails('variable_cpl', 1)
                                        }}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                } label="Variable" />

                                <FormControlLabel control={
                                    <Checkbox
                                        checked={this.state.data.variable_cpl === 0}
                                        onChange={e => {
                                            this.updateFormDetails('variable_cpl', 0)
                                        }}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                } label="Fixed" />
                                </>
                            }

                            {this.state.data.variable_cpl === 0 ? 
                            <>
                                <LightTooltip title={`Please specify the cost per lead to which the client has agreed to pay ${process.env.ORGANIZATION} for this media buy.`}><span className="label">{process.env.ORGANIZATION === "LeadMark" ? 'LeadMark' : 'TA'} CPL</span></LightTooltip>
                                <MBTextField
                                    readonly={this.state.readonly}
                                    onChange={value => this.singleChange(value, 'lm_cpl')}
                                    value={this.state.data.lm_cpl}
                                    inputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </>
                            : null}
                            
                            {/* <span style={{marginTop: '20px'}} className="label">{process.env.ORGANIZATION === "LeadMark" ? 'LM CPL' : 'SA/TA CPL'}</span>
                            <MBTextField
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'lm_cpl')}
                                value={this.state.data.lm_cpl}
                                inputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                            /> */}

                            {this.state.data.media_buy_id ?
                            <>
                                <LightTooltip title={`This value represents ${process.env.ORGANIZATION}’s actual incurred marketing costs to date for this media buy.`}><span style={{marginTop: '20px'}} className="label">{process.env.ORGANIZATION === "LeadMark" ? 'Current LeadMark Costs' : 'Current SA/TA Costs'}</span></LightTooltip>
                                {this.state.data.variable_cpl == 0 ?
                                <NumberFormat value={this.state.data.lm_cost} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                :
                                <MBTextField
                                    readonly={this.state.readonly}
                                    onChange={value => this.singleChange(value, 'lm_cost')}
                                    value={this.state.data.lm_cost}
                                    inputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />}
                            </>
                            : null}
                        </Grid>
                        
                        <Grid className="report_filter" style={gridStyles}>
                            <LightTooltip title={`Please select the type of link required for this media buy.`}><span className="label normal">URL Type</span></LightTooltip>
                            <AutocompleteSingle
                                error={errors.url_type}
                                readonly={this.state.readonly}
                                onChange={value => this.singleChange(value, 'url_type')}
                                value={this.state.data.url_type}
                                options={url_types}
                                labelField="label"
                                valueField="value"
                            />
                            {!this.state.readonly ?
                            <>
                            {
                                this.state.data.url_type === "api" &&
                                <APILinkOptions ref={this.apiLinkOptions} deps={this.state.deps} mainFormDetails={this.state.data} updateFormDetails={this.updateFormDetails.bind(this)} />
                            }
                            {
                                this.state.data.url_type === "cto" &&
                                <CTOLinkOptions ref={this.ctoLinkOptions} deps={this.state.deps} mainFormDetails={this.state.data} updateFormDetails={this.updateFormDetails.bind(this)} />
                            }
                            {
                                this.state.data.url_type === "webinar" &&
                                <WebinarLinkOptions mainFormDetails={this.state.data} deps={this.state.deps} updateFormDetails={this.updateFormDetails.bind(this)} />
                            }
                            {
                                this.state.data.url_type === "odw_webinar" &&
                                <ODWLinkOptions mainFormDetails={this.state.data} deps={this.state.deps} updateFormDetails={this.updateFormDetails.bind(this)} />
                            }
                            {
                                this.state.data.url_type === "landing_page" &&
                                <LandingPageLinkOptions ref={this.landingPage} deps={this.state.deps} mainFormDetails={this.state.data} updateFormDetails={this.updateFormDetails.bind(this)} />
                            }
                            </>
                            : null}
                            </Grid>
                        </>
                        : null}
                        </>
                        :null}
                        {!this.state.readonly ?
                        <Grid style={{display: 'flex', width: '100%', borderTop: '1px solid #ECECEC', marginTop: '20px', padding: '20px'}}>
                            <button style={{marginLeft: 'auto'}} onClick={e => this.cancel(e)} className="btn white">Cancel</button>
                            <button type="submit" style={{marginLeft: '10px'}} className="btn">Save</button>
                        </Grid>
                        : null}
                    </>
                }
                </Grid>
                {this.state.data.creator_name ? <div style={{padding: '10px', fontWeight:300, textAlign:'right'}}>Creator: {this.state.data.creator_name}</div>: null}
            </form>
                }
            }
            </Formik>
        )
    }
}

export default MediaBuyRequestForm