import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField, Checkbox, Typography } from "@material-ui/core"
import NumberFormat from "react-number-format";
import CreateIcon from '@material-ui/icons/Create';

const getOption = (options, value, field) => {
    let valueField = field ? field : 'value';
    let found = options.find(e => {
        if(e[valueField] === value) {
            return e;
        }
    })
    if(found) return found
    else return null
}

const getOptionLabel = props => {
    const option = getOption(props.options, props.value, props.valueField);
    if(option !== null) {
        return props.labelField ? option[props.labelField] : option.label; 
    } else {
        if(props.value === null) {
            return props.emptyLabel
        } 
    }
    return '';
}


export const AutocompleteSingle = props => {
    const onChange = (e, value) => {
        if(value.id === null || (props.valueField) && value[props.valueField] ===null) {
            return props.onChange(null)
        }
        return (props.valueField ? value !== undefined ? value[props.valueField]: value : value['id']) === 'create_new' ? props.onCreate(value) : props.onChange(props.valueField ? {value: value !== undefined ? value[props.valueField]: undefined} : value)
    }

    const onEdit = value => {
        props.onEdit(value);
    }

    let options = [...props.options];
    if(props.emptyLabel) {
        options.unshift({
            [props.labelField]: props.emptyLabel,
            [props.valueField]: null
        })
    }
    if(props.onCreate) {
        options.unshift({
            label: props.createLabel,
            id: `create_new`
        })
    }
    return (
        
        <>
            {props.readonly ?
                props.customReadonly ? null :
                <Typography variant="subtitle1" gutterBottom>
                    {getOptionLabel(props)}
                </Typography>
            :
                <>
                <Autocomplete
                    options={options}
                    getOptionLabel={(option) => option[props.labelField] ? option[props.labelField] : ''}
                    autoHighlight
                    size="small"
                    required={props.required ? true : false}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            {option.id && option.id === 'create_new' ?
                                <button className='btn light' onClick={() => props.onCreate()}>{option.label}</button>
                            :
                            <span style={{display: 'block', width: '90%'}} onClick={e => onChange(e, option)}>
                                <Checkbox
                                    style={{ marginRight: 4 }}
                                    checked={option[props.valueField] ? option[props.valueField] == props.value : (props.value !== undefined && props.value == option.value)}
                                />
                                {props.labelField ? option[props.labelField] : option.label}
                            </span>
                            }
                            {option.id !== 'create_new' && (props.valueField ? option[props.valueField]: option.id) !== null && props.onEdit && (props.valueField ? option[props.valueField] : 'id') !== 'create_new' ?
                                <CreateIcon onClick={() => onEdit(option)} style={{marginLeft: 'auto'}} />
                            : null}
                        </React.Fragment>
                    )}
                    renderInput={params => {
                        let label = getOptionLabel(props);
                        if(params.inputProps.ref.current !== null && params.inputProps.ref.current !== document.activeElement) {
                            params.inputProps.value = label
                        }
                        return <TextField variant="outlined" {...params} />
                    }}
                    value={getOption(props.options, props.value, props.valueField)}
                />
                {props.error ? <small style={{
                    color: 'rgb(244, 67, 54)'
                }}>{props.error}</small> : null}
                </>
            }
        </>
    )
}

export const MBTextField = props => {
    return (
        <>
            {props.readonly ?
                props.customReadonly ? null :
                <Typography variant="subtitle1" gutterBottom>
                    {props.inputProps ? <NumberFormat value={props.value} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : props.value}
                </Typography>
            :
                <TextField className='mbTextField' variant="outlined" style={{width: '100%'}} InputProps={props.inputProps} onChange={e => props.onChange(e.target.value)} value={props.value}></TextField>
            }
        </>
    )   
}